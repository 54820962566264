<template>
	<div class="cassie-vertical-sm">
		<div
			v-if="canSeeContactDetails || canSeePreferences"
			class="cassie-vertical-md"
		>
			<div v-if="submission.merged">
				<v-card-title class="py-2 px-0">
					Merged
				</v-card-title>
			</div>
			<div v-else>
				<div
					v-if="isMergedRecord"
					class="py-2 px-0"
				>
					Merged from ID: {{ submission.syrenisId }} {{ caption }}
				</div>
				<div>
					<v-card-title class="py-2 px-0">
						Contact Details Changes
					</v-card-title>
					<v-row>
						<v-col :cols="12">
							<DataTable
								v-if="submission.submittedContactDetails.length > 0 && canSeeContactDetails"
								light
								:headers="contactDetailsTableHeaders"
								:items="submission.submittedContactDetails.filter(x=> !x.fieldName.toLowerCase().includes('utc'))"
								hide-default-footer
							/>
							<p v-else>
								No changes to contact details
							</p>
						</v-col>
					</v-row>
				</div>
				<div class="mb-2">
					<v-card-title class="py-2 px-0">
						Preference Changes
					</v-card-title>
					<v-row>
						<v-col :cols="12">
							<DataTable
								v-if="submission.submittedPreferences.length > 0 && canSeePreferences"
								light
								:headers="preferenceTableHeaders"
								:items="submission.submittedPreferences"
								hide-default-footer
							>
								<template #item.privacyPolicyName="{ item }">
									<span v-if="item.privacyPolicyUrl">
										{{ item.privacyPolicyName }} -
										<a
											:href="item.privacyPolicyUrl"
											target="blank"
										>
											View
										</a>
									</span>
									<span v-else>
										{{ item.privacyPolicyName }}
									</span>
								</template>
								<template #item.newValue="{ item }">
									<span>
										{{ item.newValue }}
									</span>
								</template>
							</DataTable>
							<p v-else>
								No changes to preferences
							</p>
						</v-col>
					</v-row>
				</div>
			</div>
			<SecondaryActionButton
				v-if="canSeeSubmissionString && submission.submissionId !== null"
				@click="openSubmissionModal()"
			>
				Show Raw Submission
			</SecondaryActionButton>
			<Modal
				v-if="canSeeSubmissionString && submission.submissionId !== null && showSubmissionStringModal"
				width="600px"
			>
				<template #modal-title>
					Show Raw Submission
					<v-spacer />
					<IconButton @click="closeSubmissionModal()">
						mdi-close
					</IconButton>
				</template>
				<template #modal-content>
					<div class="cassie-vertical-md">
						<vue-json-pretty
							v-if="validJson && rawSubmissionString"
							:data="rawSubmissionString"
						/>
						<div v-else>
							{{ rawSubmissionString }}
						</div>
					</div>
				</template>
				<template #modal-footer>
					<SecondaryActionButton @click="closeSubmissionModal">
						Close
					</SecondaryActionButton>
				</template>
			</Modal>
		</div>
		<div v-else>
			<v-card-title class="py-2 px-0">
				You do not have permission to view Preference or Contact Details Changes
			</v-card-title>
		</div>
	</div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { mapGetters } from 'vuex'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { brandFieldLabel, responseChannelFieldLabel, sourceCodeFieldLabel, showValidFromAndToFieldsForPrefSubmissions, contactDetailsFields } from '../../../../../../shared/state/configuration.js'
import { getDataSubjectSubmissionString } from '../../../../../../shared/utils/api/data-subject.js'
import Modal from '../../../../../../shared/components/modal.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import {
	DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS,
	SEE_DATA_SUBJECT_SUBMISSION_STRING
} from '../../../../../../shared/permissions/cs-portal-permissions.js'
export default {
	name: 'data-subject-submission',
	components: { DataTable, VueJsonPretty, Modal, SecondaryActionButton, IconButton },
	props: {
		submission: Object,
		channelName: String,
		brandName: String,
		statementText: String,
		dateSubmittedFormatted: String,
		currentSelection: String,
		privacyPolicyName: String,
		privacyPolicyUrl: String,
		privacyPolicyVersion: String,
		canSeePreferences: Boolean,
		canSeeContactDetails: Boolean,
		syrenisId: Number,
		isMergedRecord: Boolean
	},
	setup () {
		return {
			brandFieldLabel,
			responseChannelFieldLabel,
			sourceCodeFieldLabel,
			showValidFromAndToFieldsForPrefSubmissions,
			contactDetailsFields
		}
	},
	data () {
		return {
			rawSubmissionString: null,
			loading: false,
			validJson: false,
			expanded: false,
			showSubmissionStringModal: false
		}
	},
	computed: {
		...mapGetters('auth', ['hasPermission']),
		contactDetailsTableHeaders () {
			return [
				{
					text: 'Field Name',
					value: 'fieldName'
				},
				{
					text: 'Previous Value',
					value: 'previousValue'
				},
				{
					text: 'New Value',
					value: 'newValue'
				}
			]
		},
		preferenceTableHeaders () {
			let tableHeaders = [
				{
					text: 'Channel Name',
					value: 'channelName'
				},
				{
					text: 'Statement Text',
					value: 'statementText'
				},
				{
					text: 'Country',
					value: 'brandName'
				},
				{
					text: 'Privacy Policy',
					value: 'privacyPolicyName'
				},
				{
					text: sourceCodeFieldLabel.value,
					value: 'sourceCode'
				},
				{
					text: responseChannelFieldLabel.value,
					value: 'responseChannel'
				},
				{
					text: 'Previous Selection',
					value: 'previousValue'
				},
				{
					text: 'New Selection',
					value: 'newValue'
				},
				{
					text: 'Valid From',
					value: 'validFrom'
				},
				{
					text: 'Valid To',
					value: 'validTo'
				}
			]

			if (!showValidFromAndToFieldsForPrefSubmissions.value) {
				tableHeaders = tableHeaders.filter(({ value }) => value !== 'validFrom' && value !== 'validTo')
			}
			return tableHeaders
		},
		hasFullPermissions () {
			return this.hasPermission(DATA_SUBJECT_MANAGEMENT_AREA_FULL_PERMISSIONS)
		},
		canSeeSubmissionString () {
			if (this.hasFullPermissions) {
				return true
			}
			return this.hasPermission(SEE_DATA_SUBJECT_SUBMISSION_STRING)
		},
		caption () {
			const a = this.contactDetailsFields
				.filter(field => field.showInOverview)
				.map(({ fieldName }) => {
					let val
					for (const [key, value] of Object.entries(this.submission.contactDetails)) {
						if (key.toLowerCase() === fieldName) {
							val = value
							break
						}
					}
					return val
				})
				.join(', ')
			return a
		}
	},
	methods: {
		openSubmissionModal () {
			if (this.rawSubmissionString) return
			if (this.canSeeSubmissionString) {
				this.loading = true
				getDataSubjectSubmissionString(this.submission.submissionId)
					.then(data => {
						try {
							this.validJson = true
							this.rawSubmissionString = JSON.parse(data) ?? 'Submission String is not available'
						} catch (error) {
							this.validJson = false
							this.rawSubmissionString = data ?? 'Submission String is not available'
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
			this.showSubmissionStringModal = true
		},
		closeSubmissionModal () {
			this.rawSubmissionString = null
			this.validJson = false
			this.showSubmissionStringModal = false
		}
	}
}
</script>
