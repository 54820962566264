<template>
	<v-expansion-panels
		v-model="panel"
		multiple
		class="cassie-data-subject-associated-devices-expansion-panels"
	>
		<v-expansion-panel
			v-for="(device, deviceIndex) in devices"
			:key="deviceIndex"
			class="mb-3"
		>
			<v-expansion-panel-header
				color="nav-bar-background white--text"
			>
				Device ID: {{ device.associatedSyrenisId }}
				<template #actions>
					<v-icon class="white--text">
						mdi-chevron-down
					</v-icon>
				</template>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<DataTable
					:headers="preferenceTableHeaders"
					:items="preferenceHistoryItems(deviceIndex)"
					:sort-by="sortBy"
					:sort-desc="sortDesc"
					item-key="index"
					single-expand
					show-expand
					@item-expanded="rowExpanded"
				>
					<template #item.isCurrent="{ item }">
						<v-icon>
							{{ item.isCurrent ? 'mdi-check' : 'mdi-close' }}
						</v-icon>
					</template>
					<template #expanded-item="{ item }">
						<td colspan="12">
							<DataSubjectPreferenceHistoryItem
								:preference="item"
								:current-expanded-item="currentExpandedItem"
								:syrenis-id="device.associatedSyrenisId"
								class="mt-4"
							/>
						</td>
					</template>
				</DataTable>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import {
	getDataSubjectHistory
} from '../../../../../../shared/utils/api/data-subject.js'
import DataSubjectPreferenceHistoryItem from './data-subject-preference-history-item.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import { brandFieldLabel } from '../../../../../../shared/state/configuration.js'
import {
	UNIVERSAL_BRAND
} from '../../../../../../shared/state/brands.js'

export default {
	components: {
		DataSubjectPreferenceHistoryItem,
		DataTable
	},
	props: {
		devices: {
			type: Array,
			default: () => []
		},
		filterBrandId: Number,
		filterChannelName: String,
		filterIsCurrent: Boolean

	},
	setup () {
		return {
			brandFieldLabel,
			UNIVERSAL_BRAND
		}
	},
	data () {
		return {
			panel: [],
			devicePrefs: new Array(this.devices.length),
			sortBy: 'dateSubmitted',
			sortDesc: true,
			currentExpandedItem: {}
		}
	},
	computed: {
		preferenceTableHeaders () {
			return [
				{
					value: 'brandName',
					text: brandFieldLabel.value,
					width: '15%'
				},
				{
					value: 'channelName',
					text: 'Device Preference',
					width: '15%'
				},
				{
					value: 'divId',
					text: 'Source',
					width: '15%'
				},
				{
					value: 'dateSubmitted',
					text: 'Consent Date',
					width: '15%'
				},
				{
					value: 'preferenceValue',
					text: 'Preference',
					width: '15%'
				},
				{
					value: 'isCurrent',
					text: 'Is Current',
					width: '15%'
				},
				{
					text: '',
					value: 'data-table-expand'
				}
			]
		}
	},
	watch: {
		panel: async function (newValue, oldValue) {
			const newlyOpenedPanelIndex = newValue.find(item => !oldValue.some(oldItem => oldItem === item))
			if ((newlyOpenedPanelIndex ?? -1) >= 0) {
				if (!this.devicePrefs[newlyOpenedPanelIndex]) {
					const device = this.devices[newlyOpenedPanelIndex]
					const { data } = await getDataSubjectHistory(device.associatedSyrenisId)
					const mappedPreferences = data.preferences.map((pref, index) => ({
						index: index,
						...pref
					}))
					this.$set(this.devicePrefs, newlyOpenedPanelIndex, mappedPreferences)
				}
			}
		},
		devices: function () {
			this.panel = []
			this.devicePrefs = new Array(this.devices.length)
			this.currentExpandedItem = {}
		}
	},
	methods: {
		rowExpanded ({ item, value }) {
			this.currentExpandedItem = item
		},
		preferenceHistoryItems (deviceIndex) {
			if (!this.devicePrefs[deviceIndex]) {
				return []
			}
			let preferences = [...this.devicePrefs[deviceIndex]].sort((a, b) =>
				new Date(b.dateSubmitted) - new Date(a.dateSubmitted)
			)
			if (this.filterBrandId !== UNIVERSAL_BRAND) {
				preferences = preferences.filter(({ brandId }) => brandId === this.filterBrandId || brandId === UNIVERSAL_BRAND)
			}
			return preferences.map((pref, index) => ({
				...pref,
				index: index
			})).filter(({ channelName, brandId, isCurrent }) => {
				let check = true
				if (this.filterChannelName) check = channelName.toLowerCase().includes(this.filterChannelName.toLowerCase())
				if (this.filterBrandId !== 0) check = check && brandId === this.filterBrandId
				if (this.filterIsCurrent !== null) check = check && isCurrent === this.filterIsCurrent
				return check
			})
		}
	}
}
</script>

<style lang="scss">
.cassie-data-subject-associated-devices-expansion-panels {
	.v-expansion-panel-header {
		min-height: 32px !important;
		padding: 12px !important;
	}
	.v-expansion-panel-content {
		padding: 0 !important;
	}
	.v-expansion-panel-content__wrap {
		padding: 16px !important;
	}
	.v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
		margin-top: 0 !important;
	}
}
</style>
