import { render, staticRenderFns } from "./data-subject-associated-device-expansion-panels.vue?vue&type=template&id=48b44577&"
import script from "./data-subject-associated-device-expansion-panels.vue?vue&type=script&lang=js&"
export * from "./data-subject-associated-device-expansion-panels.vue?vue&type=script&lang=js&"
import style0 from "./data-subject-associated-device-expansion-panels.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports