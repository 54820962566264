<template>
	<div class="cassie-vertical-md">
		<DataSubjectBackButton @click="$emit('exit')" />
		<div
			class="d-flex align-center cassie-horizontal-md"
			style="flex: 1;"
		>
			<SecondaryActionButton
				:input-value="showFilters"
				@click="showFilters = !showFilters"
			>
				<v-icon>
					mdi-filter
				</v-icon>
				Filter
			</SecondaryActionButton>
			<template v-if="showFilters">
				<Dropdown
					v-if="showCsPortalBrandSelector"
					v-model="selectedBrandId"
					:label="`${brandFieldLabel}`"
					:items="brandFilterItems"
					:validate-field="false"
					class="cassie-input-width-xs"
				/>
				<TextField
					v-model="channelNameSearchQuery"
					label="Channel"
					:validate-field="false"
					class="cassie-input-width-xs"
				/>
				<Dropdown
					v-if="parentChannelFilterItems.length"
					v-model="selectedParentChannelId"
					label="Parent Channel"
					:items="[{text: 'View All', value: null}, ...parentChannelFilterItems]"
					:validate-field="false"
					class="cassie-input-width-xs"
				/>
				<Dropdown
					v-model="isCurrentValue"
					label="Current Preference"
					:items="currentPreferenceFilteritems"
					:validate-field="false"
					class="cassie-input-width-xs"
				/>
			</template>
		</div>
		<SectionCard>
			<template #title>
				Preference History
			</template>
			<template #body>
				<DataTable
					:headers="preferenceTableHeaders"
					:items="preferenceHistoryItems"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					item-key="index"
					single-expand
					show-expand
					@item-expanded="rowExpanded"
				>
					<template #item.isCurrent="{ item }">
						<v-icon>
							{{ item.isCurrent ? 'mdi-check' : 'mdi-close' }}
						</v-icon>
					</template>
					<template #expanded-item="{ item }">
						<td colspan="12">
							<DataSubjectPreferenceHistoryItem
								:preference="item"
								:current-expanded-item="currentExpandedItem"
								:syrenis-id="syrenisId"
								class="mt-4"
							/>
						</td>
					</template>
				</DataTable>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import DataSubjectBackButton from './data-subject-back-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import DataSubjectPreferenceHistoryItem from './data-subject-preference-history-item.vue'
import { brandFieldLabel } from '../../../../../../shared/state/configuration.js'
import {
	showCsPortalBrandSelector,
	csPortalBrandSelector,
	UNIVERSAL_BRAND
} from '../../../../../../shared/state/brands.js'
import {
	getDataSubjectHistory
} from '../../../../../../shared/utils/api/data-subject.js'

export default {
	components: {
		DataSubjectBackButton,
		DataTable,
		SectionCard,
		DataSubjectPreferenceHistoryItem,
		SecondaryActionButton,
		Dropdown,
		TextField
	},
	props: {
		syrenisId: Number,
		searchBrandId: Number
	},
	setup () {
		return {
			brandFieldLabel,
			showCsPortalBrandSelector,
			csPortalBrandSelector,
			UNIVERSAL_BRAND
		}
	},
	data () {
		return {
			sortBy: 'dateSubmitted',
			sortDesc: true,
			channelNameSearchQuery: '',
			selectedBrandId: 0,
			isCurrentValue: null,
			selectedParentChannelId: null,
			showFilters: false,
			currentExpandedItem: {},
			preferences: [],
			mergedRecords: [],
			mergedPreferences: [],
			profiles: []
		}
	},
	computed: {
		preferenceTableHeaders () {
			return [
				{
					value: 'brandName',
					text: brandFieldLabel.value,
					width: '15%'
				},
				{
					value: 'channelName',
					text: 'Channel',
					width: '15%'
				},
				{
					value: 'parentChannelName',
					text: 'Parent Channel',
					width: '15%'
				},
				{
					value: 'dateSubmitted',
					text: 'Consent Date',
					width: '15%'
				},
				{
					value: 'preferenceValue',
					text: 'Preference',
					width: '15%'
				},
				{
					value: 'isCurrent',
					text: 'Is Current',
					width: '15%'
				},
				{
					text: '',
					value: 'data-table-expand'
				}
			]
		},
		preferenceHistoryItems () {
			let preferences = [...this.preferences].sort((a, b) =>
				new Date(b.dateSubmitted) - new Date(a.dateSubmitted)
			)

			if (this.searchBrandId !== UNIVERSAL_BRAND) {
				preferences = preferences.filter(({ brandId }) => brandId === this.searchBrandId || brandId === UNIVERSAL_BRAND)
			}
			return preferences.map((pref, index) => ({
				...pref,
				index: index
			})).filter(({ channelName, brandId, isCurrent, parentChannelId }) => {
				let check = true
				if (this.channelNameSearchQuery) check = channelName.toLowerCase().includes(this.channelNameSearchQuery.toLowerCase())
				if (this.selectedBrandId !== 0) check = check && brandId === this.selectedBrandId
				if (this.isCurrentValue !== null) check = check && isCurrent === this.isCurrentValue
				if (this.selectedParentChannelId !== null) check = check && parentChannelId === this.selectedParentChannelId
				return check
			})
		},
		parentChannelFilterItems () {
			const parentChannels = [
				...new Set(this.preferences)].map(({ parentChannelId, brandId, parentChannelName }) => ({
				brandId: brandId,
				text: parentChannelName,
				value: parentChannelId
			})).filter(({ text }) => text).sort((a, b) =>
				a.text.localeCompare(b.text)
			)
			if (!this.selectedBrandId) {
				return parentChannels
			} else {
				return parentChannels.filter(({ brandId }) => brandId === this.selectedBrandId)
			}
		},
		currentPreferenceFilteritems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Current',
					value: true
				},
				{
					text: 'Historic',
					value: false
				}
			]
		},
		brandFilterItems () {
			if (this.searchBrandId !== UNIVERSAL_BRAND) {
				return csPortalBrandSelector.value.filter(({ value }) => value === this.searchBrandId || value === UNIVERSAL_BRAND)
			} else {
				return csPortalBrandSelector.value
			}
		}
	},
	watch: {
		syrenisId: function (newVal, oldVal) {
			this.getTimelineData(this.syrenisId)
		}
	},
	created () {
		this.getTimelineData(this.syrenisId)
	},
	methods: {
		rowExpanded ({ item, value }) {
			this.currentExpandedItem = item
		},
		async getTimelineData (syrenisId) {
			const profile = this.profiles.find(p => p.syrenisId === syrenisId)
			if (profile) {
				this.updateProfileData(profile)
				return
			}
			const { data: { preferences, mergedRecords } } = await getDataSubjectHistory(syrenisId)
			const newProfile = { syrenisId, preferences, mergedRecords }
			this.profiles.push(newProfile)
			this.updateProfileData(newProfile)
		},
		updateProfileData (profile) {
			this.preferences = profile.preferences
			this.mergedRecords = profile.mergedRecords
			this.extractMergedPrefs()
			this.combinePrefsWithMergedPrefs()
		},
		extractMergedPrefs () {
			this.mergedRecords.forEach(mr => {
				mr.mergedStandDaks.forEach(sd => {
					const p = mr.mergedPreferences.filter(pref => this.mergedPrefsFilter(pref, sd, mr))
						.map(pref => {
							return {
								...pref,
								syrenisId: mr.mergedSyrenisId,
								mergedStandDak: sd
							}
						})
					if (p.length > 0) {
						p.forEach(pref => {
							if (!this.mergedPreferences.some(mp => pref.preferenceId === mp.preferenceId)) {
								this.mergedPreferences.push(pref)
							}
						})
					}
				})
			})
		},
		mergedPrefsFilter (pref, sd, mr) {
			let result = pref.submissionId ? sd.submission.submissionId === pref.submissionId : false
			if (result) return true
			result = mr.mergedPreferences.some(parent =>
				sd.submission.submissionId === parent.submissionId && pref.parentChannelId === parent.channelId && pref.dateSubmittedUtc === parent.dateSubmittedUtc)
			if (result) return true
			return !pref.submissionId && pref.dateSubmittedUtc === sd.submission.submissionDateTimeUTC
		},
		combinePrefsWithMergedPrefs () {
			this.mergedPreferences.forEach(mergedPref => {
				const idx = this.preferences.findIndex(pref => pref.preferenceId === mergedPref.preferenceId)
				if (idx >= 0) {
					this.preferences[idx].mergedStandDak = mergedPref.mergedStandDak
				}	else {
					this.preferences.push(mergedPref)
				}
			})
		}
	}
}

</script>
