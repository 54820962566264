<template>
	<div class="cassie-vertical-md">
		<DataSubjectBackButton @click="$emit('exit')" />
		<div
			class="d-flex align-center cassie-horizontal-md"
			style="flex: 1;"
		>
			<SecondaryActionButton
				:input-value="showDeviceFilters"
				@click="showDeviceFilters = !showDeviceFilters"
			>
				<v-icon>
					mdi-filter
				</v-icon>
				Filter
			</SecondaryActionButton>
			<template v-if="showDeviceFilters">
				<Dropdown
					v-if="showCsPortalBrandSelector"
					v-model="filterBrandId"
					:label="`${brandFieldLabel}`"
					:items="brandFilterItems"
					:validate-field="false"
					class="cassie-input-width-xs"
				/>
				<TextField
					v-model="filterChannelName"
					label="Device Preference"
					:validate-field="false"
					class="cassie-input-width-xs"
				/>
				<Dropdown
					v-model="filterIsCurrent"
					label="Current Preference"
					:items="currentPreferenceFilteritems"
					:validate-field="false"
					class="cassie-input-width-xs"
				/>
			</template>
		</div>
		<SectionCard>
			<template #title>
				Associated Devices
			</template>
			<template #body>
				<DataSubjectAssociatedDevicePanels
					:devices="devices"
					:view-mode="true"
					:filter-brand-id="filterBrandId"
					:filter-channel-name="filterChannelName"
					:filter-is-current="filterIsCurrent"
				/>
			</template>
		</SectionCard>
	</div>
</template>

<script>
import DataSubjectBackButton from './data-subject-back-button.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataSubjectAssociatedDevicePanels from './data-subject-associated-device-expansion-panels.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import {
	showCsPortalBrandSelector,
	csPortalBrandSelector,
	UNIVERSAL_BRAND
} from '../../../../../../shared/state/brands.js'
import { brandFieldLabel } from '../../../../../../shared/state/configuration.js'

export default {
	components: {
		DataSubjectBackButton,
		SectionCard,
		DataSubjectAssociatedDevicePanels,
		SecondaryActionButton,
		Dropdown,
		TextField
	},
	props: {
		selectedProfile: Object,
		searchBrandId: Number
	},
	setup () {
		return {
			brandFieldLabel,
			showCsPortalBrandSelector,
			csPortalBrandSelector,
			UNIVERSAL_BRAND
		}
	},
	data () {
		return {
			showDeviceFilters: false,
			filterBrandId: 0,
			filterChannelName: '',
			filterIsCurrent: null,
			profiles: [],
			devices: []
		}
	},
	computed: {
		brandFilterItems () {
			const brands = csPortalBrandSelector.value.map(x => ({ ...x }))
			if (this.searchBrandId !== UNIVERSAL_BRAND) {
				return brands.filter(({ value }) => value === this.searchBrandId || value === UNIVERSAL_BRAND)
			} else {
				return brands
			}
		},
		currentPreferenceFilteritems () {
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: 'Current',
					value: true
				},
				{
					text: 'Historic',
					value: false
				}
			]
		}
	},
	watch: {
		selectedProfile: function () {
			this.setProfile()
		}
	},
	created () {
		this.setProfile()
	},
	methods: {
		setProfile () {
			const { syrenisId, devices } = this.selectedProfile
			const profile = this.profiles.find(p => p.syrenisId === syrenisId)
			if (profile) {
				this.devices = profile.devices
			} else {
				const newProfile = { syrenisId, devices }
				this.profiles.push(newProfile)
				this.devices = devices
			}
		}
	}
}
</script>
