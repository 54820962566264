<template>
	<v-card
		:color="color"
		dark
	>
		<v-card-title class="py-1 text-h6">
			<span>
				{{ title }} - {{ submission.formattedDateSubmitted }}
			</span>
			<span v-if="isMergedRecord">
				{{ "\xa0" }}(Merged!)
			</span>
			<v-spacer />
			<v-btn
				v-if="canSeePreferences"
				icon
				@click="expanded = !expanded"
			>
				<v-icon>
					{{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
				</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text
			v-show="expanded"
			class="white text--primary"
		>
			<div class="pt-4">
				<v-expand-transition>
					<div>
						<DataSubjectSubmission
							:submission="submission"
							:syrenis-id="syrenisId"
							:can-see-preferences="canSeePreferences"
							:can-see-contact-details="canSeeContactDetails"
							:is-merged-record="isMergedRecord"
							v-bind="item"
						/>
					</div>
				</v-expand-transition>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import DataSubjectSubmission from './data-subject-submission.vue'
import { auditHistoryExpandedByDefault } from '../../../../../../shared/state/configuration.js'
export default {
	name: 'data-subject-audit-history-timeline-card',
	components: { DataSubjectSubmission },
	props: {
		item: Object,
		color: String,
		title: String,
		canSeePreferences: Boolean,
		canSeeContactDetails: Boolean,
		submission: Object,
		submissionIndex: Number,
		syrenisId: Number,
		groupedProfileIds: Array
	},
	setup () {
		return {
			auditHistoryExpandedByDefault
		}
	},
	data () {
		return {
			expanded: false
		}
	},
	computed: {
		isMergedRecord () {
			return this.syrenisId !== this.submission.syrenisId && (!Array.isArray(this.groupedProfileIds) || !this.groupedProfileIds.includes(this.submission.syrenisId))
		}
	},
	created () {
		if (this.submissionIndex + 1 <= auditHistoryExpandedByDefault.value) {
			this.expanded = true
		}
	}
}
</script>
